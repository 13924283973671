import React, { useState, useEffect, useRef } from 'react';
import * as signalR from '@microsoft/signalr';

function App() {
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [user, setUser] = useState('');
  const [message, setMessage] = useState('');
  const connectionRef = useRef(null);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://www.emirhankurtulus.com/chatHub")
      .build();

    connection.start()
      .then(() => {
        console.log("SignalR bağlantısı kuruldu.");
        connection.on("ReceiveMessage", (receivedUser, receivedMessage) => {
          console.log("Gelen Mesaj:", receivedUser, receivedMessage);
          setReceivedMessages(prevMessages => [
            ...prevMessages,
            { user: receivedUser, message: receivedMessage },
          ]);
        });
      })
      .catch(err => console.error("SignalR bağlantı hatası: ", err));

    connectionRef.current = connection;
  }, []);

  useEffect(() => {
    console.log("Alınan Mesajlar:", receivedMessages);
  }, [receivedMessages]);

  const sendMessageToServer = () => {
    connectionRef.current.invoke("SendMessage", user, message)
      .catch(err => console.error("Mesaj gönderme hatası: ", err));

    setMessage('');
  };

  return (
    <div className="App" style={{ margin: '2rem' }}>
      <h1 style={{ textAlign: 'center' }}>Agalarla Mesajlaşma</h1>
      <div style={{ marginBottom: '1rem' }}>
        <label style={{ marginRight: '1rem' }}>User:</label>
        <input
          type="text"
          value={user}
          onChange={e => setUser(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <label style={{ marginRight: '1rem' }}>Message:</label>
        <input
          type="text"
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
      </div>
      <button onClick={sendMessageToServer} style={{ marginBottom: '1rem' }}>
        Mesaj Gönder
      </button>
      <div>
        <h2>Alınan Mesajlar:</h2>
        <ul style={{ paddingLeft: '1rem' }}>
          {receivedMessages.map((msg, index) => (
            <li key={index}>
              <strong>{msg.user}:</strong> {msg.message}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default App;
